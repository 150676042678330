import { deleteAbandonedCartRecoveryRequestFromIdb } from '@/abandoned-cart/recovery-request/abandoned-cart-recovery-request.utilities';
import type { Booking } from '@/booking/booking';
import { BookingCreateResultType } from '@/booking/create/result/booking-create-result';
import type {
  BookingCreateConfirmedResult,
  BookingCreateResult,
} from '@/booking/create/result/confirmed/booking-create-confirmed-result';
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import { BookingSourceType } from '@/booking-source/booking-source';
import { useBookingSourceStore } from '@/booking-source/booking-source.store';
import { useFacebookPixelStore } from '@/property/facebook-pixel/facebook-pixel.store';
import {
  emitAdwordsBookingCompleteEvent,
  emitAdwordsHpaBookingCompleteEvent,
} from '@/property/google/adwords/google-adwords.utilities';
import { useGoogleAnalyticsStore } from '@/property/google/analytics/google-analytics.store';
import router, { BOOKING_CONFIRMATION_ROUTE } from '@/router';
import { UserEventActionLabel } from '@/user-event/user-event';
import { useUserEventStore } from '@/user-event/user-event.store';

export const useBookingConfirmationNavigation = () => {
  const bookingItineraryStore = useBookingItineraryStore();
  const { trackUserEventAction } = useUserEventStore();
  const { emitGABookingCompleteEvent } = useGoogleAnalyticsStore();
  const { emitPixelBookingCompleteEvent } = useFacebookPixelStore();

  const goToBookingConfirmation = async (
    booking: Booking,
    accessToken: string,
  ) => {
    const bookingSourceStore = useBookingSourceStore();

    trackUserEventAction({
      label: UserEventActionLabel.BookingComplete,
      metadata: {
        widget_id: booking.widgetId,
        property_id: booking.property.id,
        booking_id: booking.id,
        is_express_checkout: booking.usedExpressCheckout,
        used_elements: booking.usedElements,
        payment_gateway: booking.property.paymentGateway.type,
      },
    });

    void emitGABookingCompleteEvent(booking);

    void emitAdwordsBookingCompleteEvent(booking);

    if (
      bookingSourceStore.bookingSource?.type === BookingSourceType.GoogleHPA
    ) {
      void emitAdwordsHpaBookingCompleteEvent(booking);
    }

    emitPixelBookingCompleteEvent(booking);

    bookingItineraryStore.unitItinerary = [];

    void deleteAbandonedCartRecoveryRequestFromIdb(
      booking.property.id,
      booking.widgetId,
    );

    await router.push({
      name: BOOKING_CONFIRMATION_ROUTE,
      params: {
        booking: JSON.stringify(booking),
        bookingId: booking.id,
      },
      query: { access_token: accessToken },
    });
  };

  const goToBookingConfirmationWithBookingCreateConfirmedResult = async ({
    booking,
    accessToken,
  }: BookingCreateConfirmedResult) => {
    await goToBookingConfirmation(booking, accessToken);
  };

  const goToBookingConfirmationWithBookingCreateResult = async (
    bookingCreateResult: BookingCreateResult,
  ) => {
    if (bookingCreateResult.type !== BookingCreateResultType.Confirmed) {
      throw new Error(
        `Unexpected booking create result type: ${bookingCreateResult.type}`,
      );
    }

    await goToBookingConfirmationWithBookingCreateConfirmedResult(
      bookingCreateResult,
    );
  };

  return {
    goToBookingConfirmation,
    goToBookingConfirmationWithBookingCreateConfirmedResult,
    goToBookingConfirmationWithBookingCreateResult,
  };
};
